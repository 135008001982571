import React from 'react'
import { IconContext } from 'react-icons'
import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaPinterestSquare,
} from 'react-icons/fa'
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
} from 'react-share'
import PropTypes from 'prop-types'
import styles from './socialMediaShare.module.css'

const SocialMediaShare = ({ socialConfig, tags }) => (
  <div className={styles.postSocial}>
    <FacebookShareButton
      url={socialConfig.config.url}
      className={styles.button}
    >
      <IconContext.Provider value={{ color: '#3b5998', size: 30 }}>
        <FaFacebookSquare />
      </IconContext.Provider>
    </FacebookShareButton>
    <TwitterShareButton
      url={socialConfig.config.url}
      className={styles.button}
      title={socialConfig.config.title}
      via={socialConfig.twitterHandle.split('@').join('')}
      hashtags={tags}
    >
      <IconContext.Provider value={{ color: '#1da1f2', size: 30 }}>
        <FaTwitterSquare />
      </IconContext.Provider>
    </TwitterShareButton>
    <PinterestShareButton
      className={styles.button}
      url={socialConfig.config.url}
      media={socialConfig.config.media}
      description={socialConfig.config.description}
    >
      <IconContext.Provider value={{ color: '#c8232c', size: 30 }}>
        <FaPinterestSquare />
      </IconContext.Provider>
    </PinterestShareButton>
  </div>
)

SocialMediaShare.propTypes = {
  socialConfig: PropTypes.shape({
    twitterHandle: PropTypes.string.isRequired,
    config: PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      media: PropTypes.string,
      description: PropTypes.string,
    }),
  }).isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
}
SocialMediaShare.defaultProps = {
  tags: [],
}

export default SocialMediaShare
