import React, { useContext } from 'react'
import BlogContext from '../../../contexts/blogData/blogData'
import {
  getCurrentVideo,
  getIdsByCategory,
  getVideosByIds,
  randomizeVideos,
} from '../suggestedVideoHelpers'
import SuggestedVideos from '../SuggestedVideos'
import VideoContext from '../../../contexts/videoData/videoData'
import PropTypes from 'prop-types'

const computeRelatedVideosByCategory = (
  currentCategory,
  postVideoId,
  allPosts,
  allVideos
) => {
  const currentVideo = getCurrentVideo(allVideos, postVideoId)
  const allVideoIdsByCategory = getIdsByCategory(
    allPosts,
    currentCategory,
    postVideoId
  ).filter(id => id !== postVideoId)

  const suggestedVideosByCategory = getVideosByIds(
    allVideoIdsByCategory,
    allVideos
  )
  return [
    ...currentVideo,
    ...randomizeVideos(suggestedVideosByCategory).slice(0, 2),
  ]
}

const RelatedVideosBySingleCategoryForSinglePost = ({
  postVideoId,
  category,
}) => {
  const allVideos = useContext(VideoContext)
  const allPosts = useContext(BlogContext)

  const suggestedVideos = computeRelatedVideosByCategory(
    category,
    postVideoId,
    allPosts,
    allVideos
  )
  return <SuggestedVideos videos={suggestedVideos} />
}

RelatedVideosBySingleCategoryForSinglePost.propTypes = {
  postVideoId: PropTypes.string,
  category: PropTypes.string,
}

export default RelatedVideosBySingleCategoryForSinglePost
