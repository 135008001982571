import React from 'react'
import SocialMediaShare from '../SocialMediaShare/SocialMediaShare'
import PropTypes from 'prop-types'
import styles from './subHeading.module.css'

const computePublishedDateString = date => {
  const formattedDate = new Date(`${date}`)
  const month = formattedDate.toLocaleString('default', { month: 'long' })
  const day = formattedDate.getDate()
  const year = formattedDate.getFullYear()
  return `${month} ${day}, ${year}`
}

const SubHeading = ({ author, date, socialMediaConfig, categories }) => (
  <div className={styles.postSubheading}>
    <div className={styles.publishedDate}>
      {computePublishedDateString(date)}
      <span className={styles.author}>By {author}</span>
    </div>
    <div className={styles.sharingIcons}>
      <div className={styles.shareLabel}>Share:</div>
      <div>
        <SocialMediaShare socialConfig={socialMediaConfig} tags={categories} />
      </div>
    </div>
  </div>
)

SubHeading.propTypes = {
  author: PropTypes.string,
  date: PropTypes.string,
  socialMediaConfig: PropTypes.object,
  categories: PropTypes.array,
}

export default SubHeading
