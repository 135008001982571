import React, { useContext } from 'react'
import ArticleButton from '../../components/sharedComponents/articleButton/articleButton'
import ArticleTitle from '../../components/sharedComponents/ArticleTitle/ArticleTitle'
import BlogContext, { BlogData } from '../../contexts/blogData/blogData'
import { CampingData } from '../../contexts/campingData/campingData'
import CategoryLinkSelector from '../../components/Selectors/CategoryLinkSelector/CategoryLinkSelector'
import { getFluidImage } from '../../helpers/dynamicGatsbyImage'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { LodgingData } from '../../contexts/lodgingData/lodgingData'
import NearbyCampingLodging from '../../components/NearbyCampingLodging/NearbyCampingLodging'
import RelatedVideosBySingleCategoryForSinglePost from '../../components/SuggestedVideos/RelatedVideosBySingleCategoryForSinglePost/RelatedVideosBySingleCategoryForSinglePost'
import RelatedVideosByTags from '../../components/SuggestedVideos/RelatedVideosByTags/RelatedVideosByTags'
import SEO from '../../components/seo'
import SideBar from '../../components/sideBar/SideBar'
import SubHeading from '../../components/sharedComponents/SubHeading/SubHeading'
import TwoColumnLayout from '../../components/twoColumnLayout/TwoColumnLayout'
import { VideoData } from '../../contexts/videoData/videoData'
import PropTypes from 'prop-types'
import styles from './adventure.module.css'
import MediaQuery from '../../components/sharedComponents/MediaQuery/MediaQuery'
import AdSense from 'react-adsense'

const queries = {
  mobile: '(max-width: 999px)',
  desktop: '(min-width: 1000px)',
}

const Adventure = ({
  postId,
  location,
  fluidImg,
  url,
  twitterHandle,
  fullSlug,
}) => {
  const currentPost = useContext(BlogContext).filter(
    post => post.videoId === postId
  )
  const {
    seo_title,
    seo_description,
    thumbnail,
    title,
    author,
    date,
    alt,
    categories,
    displayName,
    videoId,
    camping,
    html,
    place_id,
    summary,
    slug,
  } = currentPost[0]
  const socialMediaConfig = {
    twitterHandle,
    config: {
      title,
      url: `${url}${fullSlug}`,
      media: `${url}${thumbnail}`,
      description: summary,
    },
  }

  const currentCategory = location.state ? location.state.currentCategory : null
  const matchPoints = MediaQuery(queries)
  return (
    <>
      <SEO
        title={seo_title}
        description={seo_description}
        thumbnail={thumbnail}
        url={`${url}${fullSlug}`}
        type="article"
      />
      <TwoColumnLayout>
        <div>
          <ArticleTitle>{title}</ArticleTitle>
          <SubHeading
            author={author}
            date={date}
            socialMediaConfig={socialMediaConfig}
            categories={categories}
          />
          <div className={styles.imageContainer}>
            <Img fluid={fluidImg} alt={alt} />
          </div>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: html }}
          ></div>
          <div className={styles.moreInfo}>
            Interested in learning more about{' '}
            <span className={styles.moreInfoDisplayName}>{displayName}</span>?
            Consider checking out our video, or get directions, pricing info,
            hours of operation and much more on our information page. Links
            below.
          </div>
          <div className={styles.articleLinks}>
            <ArticleButton
              icon="read"
              url={`/information/${slug}`}
              color="#b63d2b"
              text="Information"
            />
            <ArticleButton
              icon="video"
              url="/videos"
              videoId={videoId}
              color="#9f211c"
              text="Watch Our Video"
            />
          </div>

          <VideoData>
            {camping && camping[0] ? (
              <NearbyCampingLodging
                destination_id={place_id}
                campId={camping[0]}
                name={displayName}
              />
            ) : null}
          </VideoData>
        </div>
        <SideBar>
          <VideoData>
            {currentCategory ? (
              <RelatedVideosBySingleCategoryForSinglePost
                postVideoId={videoId}
                category={currentCategory}
              />
            ) : (
              <RelatedVideosByTags
                postVideoId={videoId}
                postCategories={categories}
              />
            )}
          </VideoData>
          <CategoryLinkSelector />
          {matchPoints && matchPoints.desktop ? (
            <AdSense.Google
              client="ca-pub-1156710604979342"
              slot="7244598808"
              style={{
                display: 'inline-block',
                width: '300px',
                height: '250px',
                position: 'sticky',
                top: '184px',
              }}
            />
          ) : null}
        </SideBar>
      </TwoColumnLayout>
    </>
  )
}

const AdventurePage = ({ data, location }) => {
  const fluidImg = getFluidImage(
    data.markdownRemark.frontmatter,
    data.allFile.nodes
  )

  const {
    site: {
      siteMetadata: { url, twitterHandle },
    },
  } = data

  const slug = data.markdownRemark.fields.slug

  return (
    <BlogData>
      <CampingData>
        <LodgingData>
          <Adventure
            postId={data.markdownRemark.frontmatter.videoId}
            location={location}
            fluidImg={fluidImg}
            url={url}
            twitterHandle={twitterHandle}
            fullSlug={slug}
          />
        </LodgingData>
      </CampingData>
    </BlogData>
  )
}
export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        videoId
        thumbnail
      }
      fields {
        slug
      }
    }
    allFile(filter: { extension: { ne: "md" } }) {
      nodes {
        childImageSharp {
          id
          fluid(maxWidth: 1210) {
            ...GatsbyImageSharpFluid
          }
        }
        relativePath
        extension
      }
    }
    site {
      siteMetadata {
        url
        twitterHandle
      }
    }
  }
`
AdventurePage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
}

Adventure.propTypes = {
  postId: PropTypes.string,
  location: PropTypes.object,
  fluidImg: PropTypes.object,
  url: PropTypes.string,
  twitterHandle: PropTypes.string,
  slug: PropTypes.string,
  fullSlug: PropTypes.string,
}
export default AdventurePage
