import React, { useContext } from 'react'
import BlogContext from '../../../contexts/blogData/blogData'
import {
  getCurrentVideo,
  getRelatedIDs,
  getVideosByIds,
  randomizeVideos,
} from '../suggestedVideoHelpers'
import SuggestedVideos from '../SuggestedVideos'
import VideoContext from '../../../contexts/videoData/videoData'
import PropTypes from 'prop-types'

const computeRelatedVideosData = (
  postCategories,
  postVideoId,
  allVideos,
  allPosts
) => {
  const currentVideo = getCurrentVideo(allVideos, postVideoId)

  const relatedPostsIDs = getRelatedIDs(postCategories, allPosts, postVideoId)

  const relatedVideos = getVideosByIds(relatedPostsIDs, allVideos)

  return [...currentVideo, ...randomizeVideos(relatedVideos).slice(0, 2)]
}

const RelatedVideosByTags = ({ postCategories, postVideoId }) => {
  const allVideos = useContext(VideoContext)
  const allPosts = useContext(BlogContext)

  const relatedVideos = computeRelatedVideosData(
    postCategories,
    postVideoId,
    allVideos,
    allPosts
  )
  return <SuggestedVideos videos={relatedVideos} />
}

RelatedVideosByTags.propTypes = {
  postCategories: PropTypes.array,
  postVideoId: PropTypes.string,
}
export default RelatedVideosByTags
